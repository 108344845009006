import * as React from "react"
import { Box, Container, Icon, hexToRgb, Link as LinkMui, stepClasses } from "@mui/material"

import { LogoSVG } from "core/src/svg/LogoSVG"
import NextLink from "next/link"
import { BaseThemeColors } from "core/src/baseThemeColors"

export const headerMaxHeightPx = 8 * 16 // 8 rem approx.

interface HeaderProps {
    children?: React.ReactNode
    hasBigLogo?: boolean
    openAllLinksInNewTab?: boolean
    disableFixed?: boolean
}

export const Header: React.FunctionComponent<HeaderProps> = ({
    children,
    hasBigLogo = false,
    openAllLinksInNewTab = false,
    disableFixed = false,
}) => {
    const [hasScrolled, setHasScrolled] = React.useState<boolean>(false)
    const [needsShrinkingLogo, setNeedsShrinkingLogo] = React.useState<boolean>(hasBigLogo)
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false)

    // Logo style objects – removes the need to repeat code.
    const bigLogoStyle = {
        width: { lg: "200px" },
        height: {
            xs: headerMaxHeightPx - 64 + "px",
            lg: "115px",
        },
    }

    const smallLogoStyle = {
        width: { lg: "159px" },
        height: headerMaxHeightPx - 64 + "px",
        transform: {
            lg: "translate(-1.5rem)",
        },
        ml: { lg: "1.35rem" },
    }

    /**
     * `useEffect` to handle component events.
     */
    React.useEffect(() => {
        const handler = () => {
            setHasScrolled(window.scrollY > 100)
        }

        const handleResize = () => {
            setMobileMenuOpen(false)
        }

        window.addEventListener("scroll", handler, {
            passive: true,
        })

        window.addEventListener("resize", handleResize, {
            passive: true,
        })

        return () => {
            window.removeEventListener("scroll", handler)
            window.removeEventListener("resize", handleResize)
        }
    })

    return (
        <>
            {mobileMenuOpen === true && (
                <Box
                    sx={(theme) => ({
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100vw",
                        height: "100vh",
                        zIndex: "999",
                        p: "1.25rem",
                        backgroundColor: BaseThemeColors.white.main,
                    })}
                >
                    <Box
                        sx={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Container
                            fixed
                            sx={{
                                position: "absolute",
                                top: 0,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    m: "1rem",
                                    mt: "2rem",
                                }}
                            >
                                <Icon
                                    sx={{
                                        transform: "scale(2)",
                                        mr: {
                                            xs: "-0.75rem",
                                            sm: "1rem",
                                            md: "1rem",
                                        },
                                        color: BaseThemeColors.blue.main,
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setMobileMenuOpen(false)
                                    }}
                                >
                                    clear
                                </Icon>
                            </Box>

                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    ml: {
                                        xs: "1.25rem",
                                        sm: "3rem",
                                        md: "3rem",
                                    },
                                    mt: "0.75rem",
                                    width: "159px",
                                    height: headerMaxHeightPx - 64 + "px",
                                    transform: "translate(-1.5rem)",
                                }}
                            >
                                <LogoSVG style={{ width: "100%", height: "100%" }} />
                            </Box>
                        </Container>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                            }}
                        >
                            <NextLink passHref href="/">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{
                                        pl: 2,
                                        mb: "1rem",
                                        fontSize: 28,
                                    }}
                                >
                                    Home
                                </LinkMui>
                            </NextLink>
                            <NextLink passHref href="/about-us">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{
                                        pl: 2,
                                        mb: "1rem",
                                        fontSize: 28,
                                    }}
                                >
                                    About Us
                                </LinkMui>
                            </NextLink>
                            <NextLink passHref href="/resources">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{
                                        pl: 2,
                                        mb: "1rem",
                                        fontSize: 28,
                                    }}
                                >
                                    Resources
                                </LinkMui>
                            </NextLink>
                            <NextLink passHref href="/contact-us">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{
                                        pl: 2,
                                        mb: "1rem",
                                        fontSize: 28,
                                    }}
                                >
                                    Contact Us
                                </LinkMui>
                            </NextLink>
                        </Box>
                    </Box>
                </Box>
            )}
            {mobileMenuOpen === false && (
                <Box
                    sx={(theme) => ({
                        position: disableFixed ? "absolute" : "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        py: 4,
                        maxHeight: headerMaxHeightPx,
                        zIndex: 50,
                        transition: "background-color 0.4s ease",

                        ...(hasScrolled === true
                            ? {
                                  backgroundColor: BaseThemeColors.white.main,
                              }
                            : {
                                  backgroundColor: "transparent",
                              }),
                    })}
                >
                    <Container
                        fixed
                        sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    position: "relative",
                                    ...(hasBigLogo
                                        ? {
                                              transition: "all .1s",
                                              ...(hasScrolled === false
                                                  ? bigLogoStyle
                                                  : smallLogoStyle),
                                          }
                                        : smallLogoStyle),
                                }}
                            >
                                <LogoSVG style={{ width: "100%", height: "100%" }} />
                                <NextLink passHref href="/">
                                    <Box
                                        component="a"
                                        title="Plus: Homepage"
                                        target={openAllLinksInNewTab ? "_blank" : undefined}
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "100%",
                                            zIndex: 1,
                                        }}
                                    ></Box>
                                </NextLink>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: {
                                    xs: "none",
                                    lg: "flex",
                                },
                            }}
                        >
                            <NextLink passHref href="/">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{ pl: 2 }}
                                >
                                    Home
                                </LinkMui>
                            </NextLink>
                            <NextLink passHref href="/about-us">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{ pl: 2 }}
                                >
                                    About Us
                                </LinkMui>
                            </NextLink>
                            <NextLink passHref href="/resources">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{ pl: 2 }}
                                >
                                    Resources
                                </LinkMui>
                            </NextLink>
                            <NextLink passHref href="/contact-us">
                                <LinkMui
                                    target={openAllLinksInNewTab ? "_blank" : undefined}
                                    underline="hover"
                                    sx={{ pl: 2 }}
                                >
                                    Contact Us
                                </LinkMui>
                            </NextLink>
                        </Box>

                        <Box
                            sx={{
                                display: {
                                    xs: "flex",
                                    lg: "none",
                                },
                            }}
                        >
                            <Icon
                                sx={{
                                    transform: "scale(1.8)",
                                    mr: "0.5rem",
                                    color: BaseThemeColors.blue.main,
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setMobileMenuOpen(true)
                                }}
                            >
                                menu
                            </Icon>
                        </Box>
                    </Container>
                </Box>
            )}
        </>
    )
}
