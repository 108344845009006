import * as React from "react"
import { Box, Container, Typography, Link as LinkMui } from "@mui/material"
import NextLink from "next/link"

import { BaseThemeColors } from "core/src/baseThemeColors"

import { LogoSVG } from "core/src/svg/LogoSVG"

interface FooterProps {
    openAllLinksInNewTab?: boolean
}

export const Footer: React.FunctionComponent<FooterProps> = ({ openAllLinksInNewTab = false }) => {
    const date = new Date()
    const year = date.getFullYear()

    const [hoveringLinkedIn, setHoveringLinkedIn] = React.useState(false)
    const [hoveringFacebook, setHoveringFacebook] = React.useState(false)
    const [hoveringTwiiter, setHoveringTwiiter] = React.useState(false)

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: {
                    md: 10,
                },
                py: {
                    xs: 8,
                    md: 10,
                },
                pb: {
                    md: 20,
                },
                backgroundColor: BaseThemeColors.white.main,
            }}
        >
            <Container
                fixed
                sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: {
                        xs: "column",
                        lg: "row",
                    },
                    flex: 1,
                }}
            >
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "50%",
                        },
                        display: "flex",
                        justifyContent: {
                            xs: "center",
                            lg: "flex-start",
                        },
                        mb: {
                            xs: 6,
                            lg: 0,
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: { xs: "100%", sm: "150px" },
                            maxWidth: "200px",
                        }}
                    >
                        <LogoSVG />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            lg: "50%",
                        },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: {
                            xs: "center",
                            lg: "flex-end",
                        },
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            mb: "1.25rem",
                        }}
                    >
                        <a
                            href="https://www.linkedin.com/company/plus-edhealth/"
                            style={{ paddingRight: "1.25rem" }}
                            target="_blank"
                        >
                            <svg
                                onMouseEnter={() => setHoveringLinkedIn(true)}
                                onMouseLeave={() => setHoveringLinkedIn(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                style={{
                                    width: "32px",
                                    transition: "all .2s",
                                    ...(hoveringLinkedIn === true
                                        ? { fill: BaseThemeColors.green.main }
                                        : { fill: BaseThemeColors.grey.main }),
                                }}
                            >
                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                            </svg>
                        </a>

                        <a
                            href="https://www.facebook.com/profile.php?id=100089339086608"
                            style={{ paddingRight: "1.25rem" }}
                            target="_blank"
                        >
                            <svg
                                onMouseEnter={() => setHoveringFacebook(true)}
                                onMouseLeave={() => setHoveringFacebook(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                style={{
                                    width: "32px",
                                    transition: "all .2s",
                                    ...(hoveringFacebook === true
                                        ? { fill: BaseThemeColors.orange.main }
                                        : { fill: BaseThemeColors.grey.main }),
                                }}
                            >
                                <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                            </svg>
                        </a>

                        <a href="https://twitter.com/Plus_wellbeing" target="_blank">
                            <svg
                                onMouseEnter={() => setHoveringTwiiter(true)}
                                onMouseLeave={() => setHoveringTwiiter(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                style={{
                                    width: "32px",
                                    transition: "all .2s",
                                    ...(hoveringTwiiter === true
                                        ? { fill: BaseThemeColors.blue.main }
                                        : { fill: BaseThemeColors.grey.main }),
                                }}
                            >
                                <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z" />
                            </svg>
                        </a>
                    </Box>
                    <Box
                        sx={{
                            mb: "1.25rem",
                            textAlign: {
                                xs: "center",
                                lg: "right",
                            },
                        }}
                    >
                        <NextLink passHref href="/">
                            <LinkMui
                                target={openAllLinksInNewTab ? "_blank" : undefined}
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    pl: 1,
                                    mb: { xs: 1, md: 0 },
                                    fontSize: 16,
                                }}
                            >
                                Home
                            </LinkMui>
                        </NextLink>{" "}
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>|</Box>
                        <NextLink passHref href="/about-us">
                            <LinkMui
                                target={openAllLinksInNewTab ? "_blank" : undefined}
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    pl: 1,
                                    mb: { xs: 1, md: 0 },
                                    fontSize: 16,
                                }}
                            >
                                About Us
                            </LinkMui>
                        </NextLink>{" "}
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>|</Box>
                        <NextLink passHref href="/contact-us">
                            <LinkMui
                                target={openAllLinksInNewTab ? "_blank" : undefined}
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    pl: 1,
                                    mb: { xs: 1, md: 0 },
                                    fontSize: 16,
                                }}
                            >
                                Contact
                            </LinkMui>
                        </NextLink>{" "}
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>|</Box>
                        <NextLink passHref href="/resources">
                            <LinkMui
                                target={openAllLinksInNewTab ? "_blank" : undefined}
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    pl: 1,
                                    mb: { xs: 1, md: 0 },
                                    fontSize: 16,
                                }}
                            >
                                Resources
                            </LinkMui>
                        </NextLink>{" "}
                    </Box>
                    <Box
                        sx={{
                            fontSize: 12,
                            color: BaseThemeColors.grey.main,
                            textAlign: {
                                xs: "center",
                                lg: "right",
                            },
                        }}
                    >
                        Company no. 14204710{" "}
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>|</Box> Copyright{" "}
                        {year} © All rights reserved.
                        <br />
                        <NextLink passHref href="/published-documents/Privacy-Policy-2023.pdf">
                            <LinkMui
                                target="_blank"
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    my: { xs: 1, md: 0 },
                                    pr: 1,
                                    fontSize: 12,
                                    color: BaseThemeColors.grey.main,
                                    textDecoration: "underline",
                                }}
                            >
                                School Privacy Policy
                            </LinkMui>
                        </NextLink>{" "}
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>|</Box>
                        <NextLink
                            passHref
                            href="/published-documents/Data-security-FAQs-for-schools-2023.pdf"
                        >
                            <LinkMui
                                target="_blank"
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    my: { xs: 1, md: 0 },
                                    pl: 1,
                                    pr: 1,
                                    fontSize: 12,
                                    color: BaseThemeColors.grey.main,
                                    textDecoration: "underline",
                                }}
                            >
                                Security FAQs for Schools
                            </LinkMui>
                        </NextLink>{" "}
                        <Box sx={{ display: { xs: "none", md: "inline-block" } }}>|</Box>
                        <NextLink passHref href="/published-documents/terms-and-conditions.pdf">
                            <LinkMui
                                target="_blank"
                                underline="hover"
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "inline-block",
                                    },
                                    mb: { xs: 1, md: 0 },
                                    pl: 1,
                                    fontSize: 12,
                                    color: BaseThemeColors.grey.main,
                                    textDecoration: "underline",
                                }}
                            >
                                School Terms and Conditions
                            </LinkMui>
                        </NextLink>{" "}
                    </Box>
                    <Box
                        sx={{
                            color: BaseThemeColors.grey.main,
                            opacity: 0.7,
                            textAlign: {
                                xs: "center",
                                lg: "right",
                            },
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{ pt: 1 }}
                            onClick={() => {
                                throw new Error(
                                    "Sentry test! Release: " + process.env.NEXT_PUBLIC_COMMIT ||
                                        "N/A"
                                )
                            }}
                        >
                            Release {process.env.NEXT_PUBLIC_COMMIT || "N/A"}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
