import * as React from "react"

interface LogoSVG {
    style?: React.CSSProperties
}

export const LogoSVG: React.FunctionComponent<LogoSVG> = ({ style }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg21"
        version="1.1"
        style={{ width: "100%", height: "100%", ...style }}
        viewBox="0 0 382.3 208.03"
        preserveAspectRatio="xMinYMid meet"
    >
        <g style={{ isolation: "isolate" }} transform="translate(-95.7 -75.43)">
            <g>
                <path
                    id="path6"
                    fill="#5cd66b"
                    d="M98.15 135.8h29.74a2.45 2.45 0 0 1 2.45 2.45v8.37c1.63-1.37 2.74-2.36 3.9-3.27 16.73-13.09 42.32-12.8 59.6.86 15.74 12.45 22.42 29.22 22.28 48.89-.13 18.35-6.47 34.11-20.6 46.29-16.52 14.22-42.12 15.76-59.33 3.61-1.84-1.3-3.53-2.81-5.83-4.66v42.36a2.76 2.76 0 0 1-2.77 2.76H98.47a2.76 2.76 0 0 1-2.77-2.76V138.25a2.45 2.45 0 0 1 2.45-2.45zm82.06 56.49a78.34 78.34 0 0 0-1.43-8.4c-5-17.81-26.13-24.41-39.63-12.24-7.64 6.89-9.75 15.68-8.45 25.31 2.06 15.13 16.7 25.6 31.39 21.41 10.96-3.12 17.85-13.37 18.12-26.08z"
                ></path>
                <path
                    id="path8"
                    fill="#1c5fca"
                    d="m224.18 136.52 29.93-.42a2.19 2.19 0 0 1 2.22 2.15v1.3c.28 20.33.52 40.66.86 61 .16 9.19 4.57 15.35 12.48 17.81 11.18 3.47 22.46-1.44 25.65-11.38a24.16 24.16 0 0 0 1-7.57c-.18-20.05-.5-40.09-.77-60.13v-2a1.67 1.67 0 0 1 1.65-1.69l31.52-.44a1.7 1.7 0 0 1 1.71 1.68v1.2q.42 30.36.83 60.7c.34 28.55-18.57 49.3-47.13 52-14.19 1.34-27.66-.59-39.84-8.33-13.39-8.52-21-21-21.65-36.73-.86-19.95-.68-57.91-.55-67.1a2.11 2.11 0 0 1 2.09-2.05z"
                ></path>
                <path
                    id="path10"
                    fill="#f18af9"
                    d="M343.63 211.25a2.37 2.37 0 0 1 2.81 1.72c1.15 4.7 3.63 8.25 8.43 9.72 6.9 2.12 13.78 1.93 20.18-1.85a6.67 6.67 0 0 0 3.59-6.68 6 6 0 0 0-4.62-5.3c-5.69-1.5-11.5-2.56-17.25-3.83-7.24-1.6-14.67-2.64-21.68-5-24.59-8.18-24.42-36.4-13.34-49.24 7.72-9 17.55-14 29-15.89 15.77-2.64 31-1.18 45.06 7.21A34.77 34.77 0 0 1 412.16 164a4.48 4.48 0 0 1 .11.5 2.42 2.42 0 0 1-1.85 2.82l-29.95 6.38a2.35 2.35 0 0 1-2.82-1.75c-2.34-12.25-17.72-13.22-25-8.13-4 2.82-4 8.24.3 10.7a36.52 36.52 0 0 0 10.13 3.6c8.74 1.93 17.65 3.14 26.31 5.35 8.66 2.21 16.38 6.35 20.62 14.67 7.36 14.46 4.8 31.75-11.94 42.95-10.83 7.25-23 10.17-35.81 10.12-11.86 0-23.41-2-33.75-8.36-8.47-5.2-14.16-12.4-16.62-22.27a2.4 2.4 0 0 1 1.83-2.93c9.89-2.14 19.76-4.24 29.91-6.4z"
                    style={{ mixBlendMode: "multiply" }}
                ></path>
                <path
                    id="path12"
                    fill="#ff641f"
                    d="M203.75 97.15h30.06a2.24 2.24 0 0 1 2.25 2.24v146.75a2.79 2.79 0 0 1-2.79 2.79h-29a2.78 2.78 0 0 1-2.78-2.79V99.43a2.28 2.28 0 0 1 2.26-2.28z"
                    style={{ mixBlendMode: "multiply" }}
                ></path>
                <rect
                    id="rect14"
                    width="26.5"
                    height="82.79"
                    x="424.11"
                    y="75.43"
                    fill="#ffbe2c"
                    rx="2.17"
                ></rect>
                <rect
                    id="rect16"
                    width="82.79"
                    height="26.5"
                    x="395.21"
                    y="103.57"
                    fill="#ffbe2c"
                    rx="1.76"
                ></rect>
            </g>
        </g>
    </svg>
)
